<template>
  <b10-legend
    :showing.sync="internalShowing"
  >
    <template
      slot="legend"
    >
      <v-simple-table>
        <template
          #default
        >
          <tbody>
            <tr>
              <td>
                <v-chip
                  color="info"
                  dark
                  small
                  label
                >
                  Policía
                </v-chip>
              </td>
              <td>
                Es necesario informar entidad o fuerza del estado
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  color="red"
                  dark
                  small
                  label
                >
                  Cliente
                </v-chip>
              </td>
              <td>
                Es necesario informar al cliente
              </td>
            </tr>

            <tr
              v-for="item in LPARTE_NOVEDAD.estados"
              :key="item.estados"
            >
              <td>
                <v-chip
                  :color="LPARTE_NOVEDAD.coloresInteger[item]"
                  dark
                >
                  <span
                    class="font-weight-bold subtitle-1"
                  >{{ $options.filters.initials(LPARTE_NOVEDAD.descripcionEstado[item]) }}</span>
                </v-chip>
              </td>
              <td>
                Estado {{ $options.filters.lowercase(LPARTE_NOVEDAD.descripcionEstado[item]) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </b10-legend>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import { LPARTE_NOVEDAD } from '@/utils/consts'

export default {
  mixins: [internalShowingMixin],
  data () {
    return {
      LPARTE_NOVEDAD
    }
  },
}
</script>
